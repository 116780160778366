import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { COLORS } from "../styles/constants"

const PolicyWrapper = styled.div`
  .submit-btn,
  .submit-btn:active,
  .submit-btn:focus,
  .submit-btn:visited {
    border-radius: 3px;
    border-color: ${COLORS.mainBlue};
    background: transparent;
    padding: 10px 30px 10px 30px;
    font-size: 18px;
    height: 50px;
    text-transform: uppercase;
    transition: all 0.5s;
    color: ${COLORS.mainBlue};
    :hover {
      background: ${COLORS.mainBlue};
      color: white;
    }
  }
  .title {
    text-align: center;
  }

  h1 {
    margin-bottom: 30px;
  }
  ol,
  ul {
    margin: 0 0 0 3em;
    padding: 0;
  }

  .wrapper-list {
    padding-bottom: 100px;
    li {
      text-align: justify;
    }
  }
`

const SecondPage = () => (
  <>
    <SEO title="Polityka prywatności" />
    <PolicyWrapper>
      <Link to="/">
        <button
          type="button"
          className="btn btn-default submit-btn form_submit"
        >
          Powrót
        </button>
      </Link>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="title">POLITYKA PRYWATNOŚCI</h1>
            <div className="wrapper-list">
              <ol type="I">
                <li>
                  Administratorem Państwa danych osobowych (dalej jako: „Dane
                  osobowe”) jest Kancelaria Radcy Prawnego Paweł Jurczyński, ul. Stefana Żeromskiego
                  4/A, 76-200 Słupsk, NIP: 5742039649 (dalej jako: „My”).
                </li>
                <li>
                  W przypadku pytań związanych z przetwarzaniem Danych
                  osobowych, prosimy o kontakt telefoniczny pod numerem:
                  534-089-846 lub pod adresem e-mail:
                  pawel@jurczynskikancelaria.pl.
                </li>
                <li>
                  Przetwarzamy Dane osobowe - w zależności od łączących nas
                  relacji - ponieważ: (a) jest to niezbędne do celów
                  wynikających z prawnie uzasadnionych interesów realizowanych
                  przez nas, a polegających na marketingu bezpośrednim naszych
                  usług (art. 6 ust. 1 lit. f RODO), (b) jest to niezbędne do
                  wykonania umowy łączącej nas z Państwem, lub do podjęcia
                  działań na Państwa żądanie, przed zawarciem umowy (art. 6 ust.
                  1 lit. b RODO) lub (c) jest to niezbędne do wypełnienia
                  obowiązku prawnego ciążącego na nas jako administratorze (art.
                  6 ust. 1 lit. c RODO).
                </li>
                <li>
                  Przetwarzamy Dane osobowe – w zależności od łączących nas
                  relacji - w celu: (a) odpowiedzi na Państwa zapytanie, (b)
                  marketingu naszych usług, (c) świadczenia na Państwa rzecz
                  usług prawnych lub (d) wypełnienia obowiązków nałożonych
                  przepisami prawa
                </li>
                <li>
                  Przetwarzamy następujące kategorie Danych osobowych:
                  <ol type="1">
                    <li>imię i nazwisko,</li>
                    <li> numer telefonu,</li>
                    <li> adres e-mail,</li>
                    <li>numer NIP,</li>
                    <li>adres</li>
                    <li>nr rachunku bankowego.</li>
                  </ol>
                </li>
                <li>
                  Dane osobowe przechowujemy, w zależności od celów, przez
                  następujące okresy czasu:
                  <ol type="1">
                    <li>
                      w celu odpowiedzi na Państwa zapytanie – nie dłużej niż
                      jest to konieczne do jej udzielania, a po tym czasie mogą
                      być przetwarzane przez okres przedawnienia ewentualnych
                      roszczeń,
                    </li>
                    <li>
                      w celach marketingowych – do czasu wniesienia sprzeciwu,
                    </li>
                    <li>
                      w celu wykonania umowy – przez okres przedawnienia
                      ewentualnych roszczeń,
                    </li>
                    <li>
                      w celu wypełnienia ciążącego na nas obowiązku prawnego –
                      przez okres wymagany przez przepis prawa.
                    </li>
                  </ol>
                </li>
                <li>
                  Informujemy, że odbiorcami Danych osobowych mogą być w
                  szczególności podmioty powiązane bezpośrednio lub pośrednio z
                  nami, w szczególności w sposób organizacyjny lub osobowy, a
                  także zewnętrzni dostawcy usług (np. podmioty świadczące
                  usługi IT i inne podmioty przetwarzające dane w naszym imieniu
                  na podstawie umowy powierzenia przetwarzania danych
                  osobowych), księgowi, biegli rewidenci i audytorzy, prawnicy,
                  organy administracji, służby państwowe i sądy.
                </li>
                <li>
                  Mają Państwo prawo do:
                  <ol type="1">
                    <li>dostępu do Danych osobowych i otrzymania ich kopii,</li>
                    <li>sprostowania Danych osobowych,</li>
                    <li>usunięcia Danych osobowych,</li>
                    <li>ograniczenia przetwarzania Danych osobowych,</li>
                    <li>
                      wniesienia sprzeciwu wobec przetwarzania Danych osobowych,
                    </li>
                    <li>przeniesienia Danych osobowych,</li>
                    <li>
                      wniesienia skargi do Prezesa Urzędu Ochrony Danych
                      Osobowych.{" "}
                    </li>
                  </ol>
                </li>
                <p>
                  {" "}
                  Państwa prawa można realizować poprzez kontakt z nami za
                  pomocą kanałów komunikacji wskazanych powyżej.
                </p>
                <li>
                  Państwa Dane osobowe przetwarzane są w naszej siedzibie. Dane
                  osobowe nie są przekazywane do krajów znajdujących się poza
                  Unią Europejską. W razie przekazania Danych osobowych do
                  państw trzecich znajdujących się poza UE zastosujemy
                  odpowiednie instrumenty mające na celu zapewnienie
                  bezpieczeństwa Danych osobowych.
                </li>
                <li>
                  W związku ze współpracą z dostawcami usług oraz innymi
                  kancelariami prawniczymi, możemy powierzać przetwarzanie
                  Danych osobowych wyżej wskazanym podmiotom. Jednakże w każdym
                  przypadku podmioty te będą zobowiązane do zachowania poufności
                  Danych osobowych oraz do przetwarzania ich zgodnie
                  z przepisami dotyczącymi ochrony danych osobowych oraz
                  odpowiednimi instrukcjami.
                </li>
                <li>
                  Przetwarzanie Danych osobowych odbywa się na zasadzie
                  dobrowolności, jednakże w zależności od okoliczności odmowa
                  udostępnienia danych lub żądanie ich usunięcia może
                  uniemożliwić nam np. kontakt z Państwem, przekazywanie Państwu
                  informacji o naszej działalności lub wykonanie usługi.
                </li>
                <li>
                  Informujemy, że nie stosujemy procedur zautomatyzowanego
                  podejmowania decyzji, w tym profilowania.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </PolicyWrapper>
  </>
)

export default SecondPage
